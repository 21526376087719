import React, { useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import qs from 'query-string';
import classNames from 'classnames/bind';

// Components
import Constructor from '../../components/Constructor';
import Preloader from '../../components/Preloader';

// Actions
import * as constructorActions from '../../state/modules/constructor/actions';
import { addCartItem } from '../../state/modules/cart/actions';

// Style
import styles from './Constructor.styl';

const cx = classNames.bind(styles);

function ConstructorPageForCard(props) {
  const location = useLocation();

  const { initialDough, initialSize, added, reset, changeDough, changeSize, fetchGood, isLoaded } = props;

  const id = useMemo(() => {
    return qs.parse(location.search, { parseNumbers: true }).product;
  }, [location.search]);

  useEffect(() => {
    // Сбрасываем значения, если повторно зашли после добавления в корзину
    if (added) {
      reset(id);
    }

    // Устанавливаем значения при переходе из карточки товара
    if (initialDough) {
      changeDough(id, initialDough);
    }

    // Устанавливаем значения при переходе из карточки товара
    if (initialSize) {
      changeSize(id, initialSize);
    }

    id && fetchGood(id);
  }, [id]);

  const isItSauce = type => type === props.intl.formatMessage({ id: 'sauce' });
  const isItSauceAfterTheOven = type => type === props.intl.formatMessage({ id: 'saucesAfterTheOven' });

  return (
    <div className={cx('ConstructorPage')}>
      {isLoaded ? <Constructor {...props} mode={'productCard'} isItSauce={isItSauce}
                               isItSauceAfterTheOven={isItSauceAfterTheOven} /> : <Preloader />}
    </div>
  );
}

const connector = connect(
  (state, props) => {
    const { location, match } = props;

    const id = state?.ui?.openedProductCardId;
    const good = state.pizzaConstructor.goods?.[id] || {};

    // если мы переходим из карточки товара (добавить/убрать ингредиенты)
    const pizzaParams = qs.parse(location.search);
    const { dough, size, stuffedCrust, removedIngredientsIds, fromProduct } = pizzaParams;

    return {
      ...good,
      id,
      removedIngredientsIds,
      initialDough: dough,
      initialSize: Number(size),
      fromProduct: fromProduct ? Number(fromProduct) : 0,
      initialStuffedCrust: stuffedCrust,
    };
  },
  dispatch => bindActionCreators({ ...constructorActions, addCartItem }, dispatch),
);

export default withRouter(connector(injectIntl(ConstructorPageForCard)));

ConstructorPageForCard.defaultProps = {
  initialStuffedCrust: 'none',
  isLoaded: false,
  added: false,
};

ConstructorPageForCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  initialDough: PropTypes.string,
  initialSize: PropTypes.string,
  initialStuffedCrust: PropTypes.string,
  changeDough: PropTypes.func.isRequired,
  changeSize: PropTypes.func.isRequired,
  toggleStuffedCrust: PropTypes.func.isRequired,
  selectStuffedCrust: PropTypes.func.isRequired,
  fetchGood: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
  added: PropTypes.bool,
  intl: PropTypes.object,
};
