import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import qs from 'query-string';
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../utils/constants';

// State
import { changeAvailableVariations } from '../../state/modules/constructor/actions';
import { removeCartItem, updateCartItem } from '../../state/modules/cart/actions';
import { unusedBonusesSum as unusedBonusesSumSelector } from '../../state/modules/user/selectors';

// Router
import { getRoute, paths } from '../../entry/routes';

// Components
import Heading from '../Heading/DesktopView';
import Button from '../Button';
import Amount from '../Amount';
import PizzaSelector from '../PizzaSelector';
import SizeSelector from '../SizeSelector';
import Modal from './Modal3';
import ProductCardModal from './ProductCardModal';
import Crust from './Crust';
import useGiftsSlider from '../GiftsSlider/useGiftsSlider';

// Partials
import AlcoholWarning from './AlcoholWarning';
import Characteristics from './Characteristics';
import Flipper from './Flipper';
import MetaOption from './MetaOption';

// Icons
import GiftIcon from '../../icons/gift.svg';
import CheeseIcon from '../../icons/cheese.svg';


// Utils
import { isAlcohol, isHalal, isHit, isHot, isNew, isPizza, isVegan, isVegeterian } from '../../utils/catalog';
import {
  getDoughTypes,
  getPersonCount,
  getSizes,
  getStuffedCrustIsAvailable,
  getStuffedCrusts,
  getVariation,
} from '../../state/modules/catalog/utils';

// Styles
import styles from './ProductCard.styl';
import { removeNumbers } from '../../utils/validators';
import { setOpenedProductCard } from '../../state/modules/ui/actions';
import Ingredients from './Ingredients';
import _isEmpty from 'lodash/isEmpty';
import FilterIcon from '../../icons/card-filter.svg';

const cx = classNames.bind(styles);

export const isTimeInterval = (enabledTimes, currentTime) => {

  let isInterval = false;
  if (enabledTimes.length > 0) {
    enabledTimes.forEach(item => {
      const timeStart =
        Number(item.time_start.slice(0, 2) * 60) + Number(item.time_start.slice(3, 5));
      const timeEnd = Number(item.time_end.slice(0, 2) * 60) + Number(item.time_end.slice(3, 5));
      if (currentTime >= timeStart - 0.5 && currentTime <= timeEnd + 0.5) {
        isInterval = true;
      }
    });
  } else {
    isInterval = true;
  }
  return isInterval;
};

function ProductCard(props) {
  const {
    className,
    stockRole,
    good,
    addToCart,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    // type может придти только из StockGoods - потому что там есть подарки
    type,
    // если мы открываем эту карту из ProductCardCompact
    compact,
    isFromProductList,
    sailplayGifts, hideModal, setIsOpenModalBonus, setIsOpenModalAuth,
  } = props;

  const { id, name, alias, data, types, show_ingredients_button, good_type, category, variations } = good;
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const unusedBonusesSum = useSelector(unusedBonusesSumSelector);
  const sailplayType = useSelector(state => state.city?.userCity?.sailplay?.type);
  const declension = useSelector(state => state.city.userCity.declension);
  const isHalloweenMode = useSelector(state => !!state.city.userCity.is_halloween_mode_on);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);
  const isPhone = useSelector(state => state.responsive.isPhone || false);
  const isDisabledLoyalty = useMemo(() => sailplayType !== 'none', [sailplayType]);
  const [currentTime, setCurrentTime] = useState(
    new Date().getHours() * 60 + new Date().getMinutes(),
  );
  const [isLoadingPoints, setIsLoadingPoints] = useState(false);

  const { list, onAddClick } = useGiftsSlider();

  const addPointProductHandler = async () => {
    await setIsLoadingPoints(true);

    if (pointPrice) {
      if (!isAuthenticated) {
        setIsOpenModalAuth(true);
      }
      if (pointPrice > unusedBonusesSum && isAuthenticated) {
        setIsOpenModalBonus(true);
      }
      const gift = list.find((item) => (variation.id === item.item.id));

      if (isAuthenticated && pointPrice <= unusedBonusesSum) {
        await onAddClick(gift);
      }
      await setIsLoadingPoints(false);
      hideModal();
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date().getHours() * 60 + new Date().getMinutes());
    }, 1000 * 60);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isVariationInCurrentTime = variations.filter(item => {
    const enabledTimes = item.enabled_times;
    return isTimeInterval(enabledTimes, currentTime);
  });

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const categoriesAliases = useSelector(state => state.catalog.products.categoriesAliases);
  const browserName = useSelector(state => state.responsive.browserName);

  const timeout = useRef({});
  const [lastAddedCartItem, setLastAddedCartItem] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenUpsaleModal, setIsOpenUpsaleModal] = useState(false);
  const [isSelectUpsale, setIsSelectUpsale] = useState(false);
  const [selectedCrust, setSelectedCrust] = useState(null);

  const product = useMemo(() => {
    return qs.parse(location.search, { parseNumbers: true }).product;
  }, [location.search]);

  // лист доступного теста
  const doughs = useMemo(() => {
    return getDoughTypes(variations);
  }, [variations]);

  const [dough, setDough] = useState(() => {
    return doughs[0]?.id;
  });

  // лист вариаций с выбранным тестом. если у товара нет теста, то просто лист вариаций
  const variationsByDough = useMemo(() => {
    return isPizza(variations[0])
      ? variations.filter(variant => variant.kind?.id === dough)
      : variations;
  }, [variations, dough]);

  // можно ли вызвать окно товара
  const isClickable = useMemo(() => {
    return isFromProductList && (category === 'pizza' || category === 'vegan');
  }, [isFromProductList, good_type]);

  // открыта/закрыта модалка
  const isOpen = useMemo(() => {
    return isClickable && isModalOpen;
  }, [isClickable, isModalOpen]);

  // лист размеров у выбранного теста
  const sizes = useMemo(() => {
    return getSizes(variationsByDough);
  }, [variationsByDough]);

  const [size, setSize] = useState(() => {
    return sizes[0]?.value;
  });

  // лист бортов для выбранного теста и размера
  const stuffedCrusts = useMemo(() => {
    return getStuffedCrusts(variationsByDough, size);
  }, [variationsByDough, size]);

  const [stuffedCrust, setStuffedCrust] = useState(() => {
    return stuffedCrusts.includes('none') ? 'none' : stuffedCrusts[0];
  });

  // вариация исходя из выбранного теста, размера, борта, корочки
  const variation = useMemo(() => {
    const newVariation = { ...getVariation(variationsByDough, size, stuffedCrust) };
    const isNewSelectedCrustAvailable = newVariation?.available_ingredients?.some(
      ingredient => ingredient.type === CRUST_EXTRA || ingredient.type === CRUST_EXTRA_EN || ingredient.type === CRUST_EXTRA_PL);
    const newSelectedCrust = newVariation?.available_ingredients?.find(
      ingredient => ingredient.type === CRUST_EXTRA || ingredient.type === CRUST_EXTRA_EN || ingredient.type === CRUST_EXTRA_PL);
    if (selectedCrust && isNewSelectedCrustAvailable && newSelectedCrust) {
      setSelectedCrust(newSelectedCrust);
      newSelectedCrust.count = 1;
      newVariation.added_ingredients = [newSelectedCrust];
    }
    return newVariation;
  }, [variationsByDough, size, stuffedCrust, selectedCrust]);

  //индикатор для проверки является ли товар подарочным (для отрисовки кнопки купить за баллы)
  const isGift = sailplayGifts?.some(item => item.item.id === variation.id);
  const currentPercent = useSelector(state => state.user?.profile?.cashback?.percent) || 0;

  const isHighLoyalty = currentPercent > 10;
  const pointPrice = sailplayGifts?.find(item => item.item.id === variation.id)?.points;
  const resetCrust = useMemo(() => ({
    reset: () => {
    },
  }), []);

  const setIsFlippedFalse = (withouReset = false) => {
    setIsFlipped(false);
    if (!withouReset) {
      // удаляем добавленную корочку
      resetCrust.reset();
      variation.added_ingredients = variation?.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN && el.type !== CRUST_EXTRA_PL,
      );
    }
  };
  const {
    price,
    old_price,
    image_list,
    image_list_webp,
    image_cart,
    image_cart_webp,
    characteristics = [],
    include_ingredients,
    available_ingredients,
  } = variation;

  const options = useMemo(() => {
    // количество людей на одну пиццу
    const personCount = getPersonCount(variation);

    return [
      isHot(types) && <MetaOption className={cx(`ProductCard__option`)} type="hot" />,
      isHalal(types) && <MetaOption className={cx(`ProductCard__option`)} type="halal" />,
      isVegeterian(types) && <MetaOption className={cx(`ProductCard__option`)} type="vegetarian" />,
      personCount && (
        <MetaOption className={cx(`ProductCard__option`)} type="person-count">
          {personCount}
        </MetaOption>
      ),
    ].filter(Boolean);
  }, [types, variation]);

  const meta = useMemo(() => {
    const result = [
      isVegan(types) && (
        <span className={cx(`ProductCard__badge`, 'ProductCard__badge_vegan')}>
          {intl.formatMessage({ id: 'vegan' })}
        </span>
      ),
      !isVegan(types) && isNew(types) && <span className={cx(`ProductCard__badge`)}>New</span>,
      !isVegan(types) && !isNew(types) && isHit(types) && (
        <span className={cx(`ProductCard__badge`)}>Hit</span>
      ),
      ...options,
    ].filter(Boolean);

    return result.length > 0 ? <div className={cx('ProductCard__meta')}>{result}</div> : null;
  }, [options, types]);

  const getSizeSelector = useCallback(
    params => {
      // доступен ли какой-то борт кроме стандартного
      const stuffedCrustIsAvailable = getStuffedCrustIsAvailable(variationsByDough, size);

      const changeDough = newDough => {
        setDough(newDough);
      };

      const changeSize = newSize => {
        setSize(newSize);
      };

      const selectStuffedCrust = newStuffedCrust => {
        setStuffedCrust(newStuffedCrust);
      };

      return isPizza(variations[0]) ? (
        <PizzaSelector
          className={params.className}
          sizes={sizes}
          currentSize={size}
          doughTypes={doughs}
          currentDough={dough}
          stuffedCrustIsAvailable={stuffedCrustIsAvailable}
          stuffedCrustsAvailable={stuffedCrusts}
          activeStuffedCrust={stuffedCrust}
          changeSize={changeSize}
          changeDough={changeDough}
          selectStuffedCrust={selectStuffedCrust}
          toggleStuffedCrust={selectStuffedCrust}
          isBottom={params.isBottom}
          specialStylesMode={params.specialStylesMode}
        />
      ) : (
        <SizeSelector
          className={params.className}
          sizes={sizes}
          currentSize={size}
          onChange={changeSize}
          specialStylesMode={params.specialStylesMode}
        />
      );
    },
    [variationsByDough, sizes, size, doughs, dough, stuffedCrusts, stuffedCrust],
  );

  const backside = useMemo(() => {
    // на задней стороне карты удаляем уже добавленный товар и добавляем его с сырным бортом
    const addToCartFromBackside = async () => {
      // переворачиваем карту
      setIsFlippedFalse(true);
      // если больше одного товара, то делаем -1
      if (lastAddedCartItem.count > 1) {
        await dispatch(
          updateCartItem({ ...lastAddedCartItem, count: lastAddedCartItem.count - 1 }),
        );
      } else {
        await dispatch(removeCartItem(lastAddedCartItem.hash));
      }

      // и добавляем вместо него новый с сырным бортом
      const cheeseVariation = { ...getVariation(variationsByDough, size, 'cheese') };
      // переносим ингредиенты в новую вариацию из текущей
      cheeseVariation.include_ingredients = variation.include_ingredients;
      // проверяем доступна ли корочка в новой вариации
      const isSelectedCrustAvailable = cheeseVariation?.available_ingredients?.some(
        ingr => ingr.id === selectedCrust?.id,
      );
      if (selectedCrust && isSelectedCrustAvailable) {
        selectedCrust.count = 1;
        cheeseVariation.added_ingredients = [selectedCrust];
      }
      setIsAdding(true);
      await addToCart(cheeseVariation);
      setIsAdding(false);
      // удаляем добавленную корочку
      resetCrust.reset();
      variation.added_ingredients = variation?.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN && el.type !== CRUST_EXTRA_PL,
      );
    };

    // если это не мобильная версия, не меню выбора акционного товара, не сам акционный товар и у него есть сырный борт
    if (!compact && !stockRole && !variation.old_price && stuffedCrusts.includes('cheese')) {
      return (
        <div className={cx('ProductCard__backside')}>
          <span className={cx('ProductCard__backside-description')}>
            {intl.formatMessage({ id: 'productcard.cheesecrust' })}
          </span>
          <CheeseIcon className={cx('ProductCard__backside-icon')} />
          <div className={cx('ProductCard__backside-buttons')} data-test-id="cheesecrust_buttons">
            <Button
              onClick={addToCartFromBackside}
              disabled={isAdding}
              className={cx('ProductCard__backside-buttons-yes')}
            >
              {intl.formatMessage({ id: 'question.yes' })}
            </Button>
            <Button
              className={cx('ProductCard__backside-buttons-no')}
              secondary
              disabled={isAdding}
              onClick={() => setIsFlippedFalse()}
            >
              {intl.formatMessage({ id: 'question.no' })}
            </Button>
          </div>
        </div>
      );
    }
    if (compact && !stockRole && !variation.old_price && stuffedCrusts.includes('cheese')) {
      return (
        <Modal
          isOpen={isOpenUpsaleModal}
          onClose={() => {
            setIsOpenUpsaleModal(false);
            setIsFlippedFalse(true);
          }}
        >
          <div className={cx('ProductCard__backside', 'ProductCard__backside_adaptive')}>
            <span
              className={cx(
                'ProductCard__backside-description',
                'ProductCard__backside-description_adaptive',
              )}
            >
              {intl.formatMessage({ id: 'productcard.cheesecrust' })}
            </span>
            <CheeseIcon
              className={cx('ProductCard__backside-icon', 'ProductCard__backside-icon_adaptive')}
            />
            <div
              className={cx(
                'ProductCard__backside-buttons',
                'ProductCard__backside-buttons_adaptive',
              )}
            >
              <Button
                onClick={async () => {
                  setStuffedCrust('cheese');
                  setIsOpenUpsaleModal(false);
                  setIsSelectUpsale(true);
                  let newVariation = { ...getVariation(variationsByDough, size, 'cheese') };
                  const isSelectedCrustAvailable = newVariation?.available_ingredients?.some(
                    ingr => ingr.id === selectedCrust?.id,
                  );
                  if (selectedCrust && isSelectedCrustAvailable) {
                    selectedCrust.count = 1;
                    newVariation.added_ingredients = [selectedCrust];
                  }

                  await addToCart(newVariation);
                  setIsFlippedFalse(false);
                }}
                disabled={isAdding}
                className={cx(
                  'ProductCard__backside-buttons-yes',
                  'ProductCard__backside-buttons-yes_adaptive',
                )}
              >
                {intl.formatMessage({ id: 'question.yes' })}
              </Button>
              <Button
                className={cx(
                  'ProductCard__backside-buttons-no',
                  'ProductCard__backside-buttons-no_adaptive',
                )}
                secondary
                disabled={isAdding}
                onClick={async () => {
                  setIsSelectUpsale(true);
                  setIsOpenUpsaleModal(false);
                  await addToCart(variation);
                  setIsFlippedFalse();
                }}
              >
                {intl.formatMessage({ id: 'question.no' })}
              </Button>
            </div>
          </div>
        </Modal>
      );
    }

    return null;
  }, [
    browserName,
    compact,
    variation,
    stuffedCrusts,
    stockRole,
    isAdding,
    lastAddedCartItem,
    variationsByDough,
    size,
    id,
    addToCart,
    isOpenUpsaleModal,
    selectedCrust,
  ]);

  const onPushToConstructor = useCallback(() => {
    dispatch(changeAvailableVariations(id, variations));

    const queryParams = qs.stringify({
      dough,
      size,
      stuffedCrust,
      fromProduct: 1,
      removedIngredientsIds: include_ingredients
        ?.filter(ingredient => ingredient.removed)
        .map(ingredient => ingredient.id),
    });

    if (id === 15) {
      history.push({ pathname: getRoute(paths.constructorAliasNotId), search: queryParams });
    } else {
      history.push({ pathname: getRoute(paths.constructorAlias, id), search: queryParams });
    }
  }, [
    id,
    variations,
    dough,
    size,
    stuffedCrust,
    include_ingredients,
    history,
    changeAvailableVariations,
  ]);

  const onAddToCart = async () => {
    const onSuccessCallback = params => {
      // если доступен сырный борт и если он еще не выбран
      if (Boolean(backside) && stuffedCrust === 'none') {
        setLastAddedCartItem(
          params.composition.find(cartItem => cartItem.item.id === variation.id),
        );
        // то переворачиваем карту товара
        setIsFlipped(true);
        timeout.current = setTimeout(() => {
          setIsFlippedFalse();
        }, 8000);
      } else {
        // удаляем добавленную корочку
        resetCrust.reset();
        delete variation?.added_ingredients;
      }
    };

    if (compact && stuffedCrust === 'none' && !isSelectUpsale) {
      setIsOpenUpsaleModal(true);
    } else {
      setIsAdding(true);
      await addToCart(variation, onSuccessCallback);
      setIsAdding(false);
      // сброс ингридиентов
      variation.added_ingredients = variation.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN && el.type !== CRUST_EXTRA_PL,
      );
      resetCrust.reset();
    }

    if (stuffedCrusts.length === 1 && stuffedCrusts.includes('none') && !backside && compact) {
      setIsAdding(true);
      await addToCart(variation, onSuccessCallback);
      setIsAdding(false);
      // сброс ингридиентов
      variation.added_ingredients = variation.added_ingredients?.filter(
        el => el.type !== CRUST_EXTRA && el.type !== CRUST_EXTRA_EN && el.type !== CRUST_EXTRA_PL,
      );
      resetCrust.reset();
    }
  };

  // комбобоксы не сочетаются с промокодами, поэтому при добавлении надо спросить
  const goToComboboxPage = () => {
    history.push(getRoute(paths.comboboxAlias, removeNumbers(alias)));
  };

  const onRemoveIngredient = useCallback(
    ingredientId => {
      removeIngredientAction({ id, variation, ingredientId });
    },
    [id, variation],
  );

  const onCancelRemovedIngredients = useCallback(() => {
    cancelRemovedIngredientsAction({ id, variation });
  }, [id, variation]);
  const currentUrl = window.location.href;

  const onModalOpen = useCallback(() => {
    const queryParams = qs.stringify({
      product: id,
      dough,
      size,
      stuffedCrust,
      fromProduct: 1,
      removedIngredientsIds: include_ingredients
        ?.filter(ingredient => ingredient.removed)
        .map(ingredient => ingredient.id),
    });
    history.push({ search: queryParams });
    dispatch(setOpenedProductCard(id));
  }, [history, id, dough, size, stuffedCrust]);

  const onModalClose = useCallback(() => {
    dispatch(setOpenedProductCard(null));
    history.push({ search: null });
  }, [history]);


  useEffect(() => {
    if (!isFlipped && timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [isFlipped]);

  useEffect(() => {
    setIsModalOpen(product === id);
  }, [product, id]);
  useEffect(() => {
    if (isPhone) {
      onModalOpen();
    }
  }, [isPhone]);

  useEffect(() => {
    if (isPhone) document.title = intl.formatMessage({ id: 'page.menu.helmet.product.modal' }, { name, declension });
  }, [good, isPhone]);

  let goodPrice = price;
  let goodOldPrice = old_price;
  const isCrustAvailable = available_ingredients?.some(ingr => ingr.id === selectedCrust?.id);
  if (selectedCrust && isCrustAvailable) {
    goodPrice += +selectedCrust.price;
    goodOldPrice += +selectedCrust.price;
  }

  const productCardCN = cx('ProductCard', className, {
    'ProductCard__hidden': isVariationInCurrentTime.length === 0,
    'ProductCard__halloween': isHalloweenMode,
    'ProductCard__new-year': isNewYearMode,
  });

  return (
    <>
      <Flipper
        className={productCardCN}
        backside={backside}
        isFlipped={isFlipped}
      >
        <picture
          className={cx('ProductCard__picture', { ProductCard__picture_clickable: isClickable })}
          onClick={isClickable && onModalOpen}
        >
          <source srcSet={compact ? image_list_webp : image_list_webp} type="image/webp" />
          {compact ? (
            <img
              className={cx('ProductCard__image')}
              alt={`${categoriesAliases[category]} ${name}`}
              src={image_list_webp}
            />
          ) : (
            <LazyLoadImage
              className={cx('ProductCard__image')}
              alt={`${categoriesAliases[category]} ${name}`}
              src={image_list_webp}
              threshold={300}
            />
          )}
        </picture>
        <div className={cx('ProductCard__container')}>
          {meta || <div style={{ height: '32px' }} />}
          <div
            className={cx(`ProductCard__header`)}
            style={data ? null : { marginBottom: '24px' }}
            data-test-id="product_card_header"
          >
            {/* Название товара */}
            <Heading className={cx(`ProductCard__heading`)} level={3}>
              {name}
            </Heading>
            <Characteristics characteristics={characteristics} specialStylesMode />

            {/* Тултип с характеристиками */}
          </div>
          {/* Описание товара */}
          {data && <div className={cx(`ProductCard__subheading`)}>{data}</div>}
          <div
            className={cx('ProductCard__actions', {
              ProductCard__actions_compact: compact,
            })}
          >
            {getSizeSelector({
              className: cx('ProductCard__selector'), specialStylesMode: true,
            })}
            {isPizza(variations[0]) ? (
              <Crust
                variation={variation}
                remove={onRemoveIngredient}
                good={good}
                resetCrust={resetCrust}
                selectedCrust={selectedCrust}
                setSelectedCrust={setSelectedCrust}
                specialStylesMode
              />
            ) : null}
            {/* В корзину */}
            <Button
              className={cx('ProductCard__button', { ProductCard__button_compact: compact })}
              onClick={good_type === 'combo' ? goToComboboxPage : onAddToCart}
              isLoading={isAdding}
            >
              {good_type === 'combo'
                ? intl.formatMessage({ id: 'combobox.card.button' })
                : intl.formatMessage({ id: type === 'gift' ? 'button.get' : 'button.inCart' })}
            </Button>
            {/* Покупка за баллы */}
            {isDisabledLoyalty && isGift && (
              <button
                className={cx('ProductCard__points', {
                  ProductCard__points_disabled: pointPrice > unusedBonusesSum,
                  // 'ProductCard__points_halloween': isHalloweenMode,
                  // 'ProductCard__points_new-year': isNewYearMode,
                })}
                onClick={addPointProductHandler}
              >
                {!isLoadingPoints ? (
                  `${pointPrice} ${intl.formatMessage({ id: 'bonus' })}`
                ) : (
                  <span className={cx('ProductCard__spinner')} />
                )}
              </button>
            )}
            {/* Цена */}
            {type === 'gift' ? (
              <GiftIcon
                className={cx('ProductCard__price', 'ProductCard__gift', {
                  ProductCard__price_compact: compact,
                })}
              />
            ) : (
              <Amount
                className={cx('ProductCard__price', { ProductCard__price_compact: compact })}
                value={goodOldPrice || goodPrice}
                newValue={goodPrice}
                specialStylesMode
              />
            )}
          </div>
          {isAlcohol(types) && <AlcoholWarning />}
          {Boolean(show_ingredients_button) && isPhone &&
            (!_isEmpty(include_ingredients) || !_isEmpty(available_ingredients)) && (
              <Ingredients
                isAdding={isAdding}
                variation={variation}
                stockRole={stockRole}
                pushToConstructor={onPushToConstructor}
                addToCart={onAddToCart}
                remove={onRemoveIngredient}
                cancel={onCancelRemovedIngredients}
                specialStylesMode
              />
            )}
          {isOpen && (
            <ProductCardModal
              isOpen={isOpen}
              good={good}
              variation={variation}
              options={options}
              getSizeSelector={getSizeSelector}
              pushToConstructor={onPushToConstructor}
              modalClose={onModalClose}
              removeIngredient={onRemoveIngredient}
              addToCart={onAddToCart}
              remove={onRemoveIngredient}
              resetCrust={resetCrust}
              selectedCrust={selectedCrust}
              setSelectedCrust={setSelectedCrust}
              value={goodOldPrice || goodPrice}
              newValue={goodPrice}
            />
          )}
        </div>
      </Flipper>
    </>


  );
}

ProductCard.defaultProps = {
  className: '',
  stockRole: '',
  type: 'good',
  compact: false,
  isFromProductList: false,
};

ProductCard.propTypes = {
  className: PropTypes.string,
  stockRole: PropTypes.string,
  good: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeIngredientAction: PropTypes.func.isRequired,
  cancelRemovedIngredientsAction: PropTypes.func.isRequired,
  type: PropTypes.string,
  compact: PropTypes.bool,
  isFromProductList: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default memo(ProductCard);
