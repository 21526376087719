import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../../utils/constants';
import config from '../../../config';

// Components
// Icons
import Done from '../../../icons/done.svg';
import Plus from '../../../icons/plus.svg';

// Style
import styles from './Crust.styl';

const cx = classNames.bind(styles);

export default function Crust(props) {
  const { isOpen, variation, resetCrust, selectedCrust, setSelectedCrust, specialStylesMode } = props;
  const isPhone = useSelector(state => state.responsive.isPhone);
  const isHalloweenMode = useSelector(state => !!state.city.userCity.is_halloween_mode_on);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);
  const crustList = variation.available_ingredients?.filter(
    item => item.type === CRUST_EXTRA || item.type === CRUST_EXTRA_EN || item.type === CRUST_EXTRA_PL,
  );

  const currency = config.lang === 'pl' ? 'zł' : '₽';

  useEffect(() => {
    resetCrust.reset = () => {
      setSelectedCrust(null);
    };
  }, []);

  return (
    <div className={cx('Crust', {
      'Crust__modal': isOpen,
      'Crust__halloween': isHalloweenMode && specialStylesMode,
      'Crust__new-year': isNewYearMode && specialStylesMode,
    })} data-test-id="crust">
      {crustList
        ? crustList.map(item => {
          return (
            <div className={cx('Crust__item', { 'Crust__item-modal': isOpen })} key={item.id}>
              {selectedCrust?.id === item.id ? (
                <Done
                  className={cx('Crust__done')}
                  color="#50A684"
                  width={14}
                  height={16}
                  onClick={() => {
                    setSelectedCrust(null);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <Plus
                  className={cx('Crust__plus')}
                  fill="#50A684"
                  width={14}
                  height={14}
                  onClick={() => {
                    setSelectedCrust(item);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              )}
              <div className={cx('Crust__text', 'Crust__name', { 'Crust__text-modal': isOpen })}>{item.name}</div>
              <div
                className={cx('Crust__currency', { 'Crust__price-modal': isOpen })}>{`${item.price} ${currency}`}</div>
            </div>
          );
        })
        : null}
    </div>
  );
}

Crust.defaultProps = {
  className: '',
};

Crust.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  variation: PropTypes.object.isRequired,
  resetCrust: PropTypes.object.isRequired,
};
