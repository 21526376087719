import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ConstructorPageForCard from '../../../scenes/Constructor/ConstructorForCart';
// Components
import Modal from '../../Modal';

// Utils
// Styles
import styles from './ProductCardModal.styl';


const cx = classNames.bind(styles);

export default function ProductCardModal(props) {
  const {
    className,
    isOpen,
    good,
    variation,
    options,
    getSizeSelector,
    pushToConstructor,
    modalClose,
    addToCart,
    removeIngredient,
    onRemoveIngredient,
    resetCrust,
    selectedCrust,
    setSelectedCrust,
    value,
    newValue,
  } = props;
  const { name, data, types, category, variations } = good;
  const {
    image_cart,
    image_cart_webp,
    characteristics = [],
    include_ingredients,
    available_ingredients,
  } = variation;
  const isNewValueExist = Boolean(newValue) || newValue === 0;
  const currentPrice = isNewValueExist && newValue !== value ? newValue : value;
  const intl = useIntl();
  const categoriesAliases = useSelector(state => state.catalog.products.categoriesAliases);
  const currencyUnit = useSelector(state => state.city.userCity.currency?.unit);
  const declension = useSelector(state => state.city.userCity.declension);
  const [isAdding, setIsAdding] = useState(false);

  const onModalClose = useCallback(() => {
    modalClose();
  }, [modalClose]);

  const onAddToCart = useCallback(async () => {
    setIsAdding(true);
    await addToCart();
    onModalClose();
    setIsAdding(false);
  }, [addToCart, onModalClose]);

  const onPushToConstructor = useCallback(() => {
    pushToConstructor();
  }, [pushToConstructor]);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'page.menu.helmet.product.modal' }, { name, declension });
  }, [good]);

  const titlePer100 = useMemo(() => {
    if (category === 'napitki') {
      return intl.formatMessage({ id: 'characteristics.tooltip.title.drinks' });
    }
    return intl.formatMessage({ id: 'characteristics.tooltip.title' });

  }, [category]);

  return (
    <Modal className={cx('ProductCardModal', className)} isOpen={isOpen} onClose={onModalClose}>
      <ConstructorPageForCard />
    </Modal>
  );
}

ProductCardModal.defaultProps = {
  className: '',
};

ProductCardModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  good: PropTypes.object.isRequired,
  variation: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  getSizeSelector: PropTypes.func.isRequired,
  pushToConstructor: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  resetCrust: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, undefined]),
};
