import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../../Heading';

// Icons
import InfoIcon from '../../../icons/info.svg';

// Styles
import styles from './Characteristics.styl';
import {useSelector} from "react-redux";

const cx = classNames.bind(styles);

export default function Characteristics(props) {
  const { className, characteristics, specialStylesMode } = props;
  const isHalloweenMode = useSelector(state => !!state.city.userCity.is_halloween_mode_on);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(prev => !prev);
  };

  const onBlur = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return characteristics.length > 0 ? (
    <div className={cx('Characteristics', className, {
        'Characteristics__halloween': isHalloweenMode && specialStylesMode,
        'Characteristics__new-year': isNewYearMode && specialStylesMode,
      })}
         onBlur={onBlur} role="button" tabIndex={0}>
      <InfoIcon
        className={cx('Characteristics__icon')}
        onClick={toggle}
        onKeyDown={e => e.key === 'Enter' && toggle()}
      />
      <Transition in={isOpen} timeout={{ enter: 0, exit: 175 }} mountOnEnter unmountOnExit>
        {state => (
          <div className={cx('Characteristics__tooltip', `Characteristics__tooltip_${state}`)}>
            <Heading className={cx('Characteristics__tooltip-title')} level={4} tagName="div">
              <FormattedMessage id="characteristics.tooltip.title" />
            </Heading>
            {characteristics.map(characteristic => (
              <p key={characteristic.code}>
                {characteristic.name}&nbsp;&mdash; {characteristic.value}
              </p>
            ))}
          </div>
        )}
      </Transition>
    </div>
  ) : null;
}

Characteristics.defaultProps = {
  className: '',
};

Characteristics.propTypes = {
  className: PropTypes.string,
  characteristics: PropTypes.array.isRequired,
};
