import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import PlantIcon from '../../../icons/plant.svg';
import PeperIcon from '../../../icons/peper.svg';
import HalalIcon from '../../../icons/halal.svg';
import PersonIcon from '../../../icons/person.svg';

// Styles
import styles from './MetaOption.styl';

const cx = classNames.bind(styles);

export default function MetaOption(props) {
  const { className, type, children } = props;

  const { isPhone, isTablet } = useSelector(state => state.responsive);
  const [isHovered, setIsHovered] = useState(false);

  const isMobile = isPhone || isTablet;

  const showTooltip = () => {
    return isMobile ? false : setIsHovered(true);
  };

  const hideTooltip = () => {
    return isMobile ? false : setIsHovered(false);
  };

  const getIcon = iconType => {
    let importedIcon = null;

    switch (iconType) {
      case 'hot':
        importedIcon = <PeperIcon width={16} height={16} />;
        break;
      case 'halal':
        importedIcon = <HalalIcon width={16} height={16} />;
        break;
      case 'vegetarian':
        importedIcon = <PlantIcon width={16} height={16} />;
        break;
      case 'person-count':
        importedIcon = <PersonIcon width={16} height={16} />;
        break;
      default:
        break;
    }

    return importedIcon;
  };

  const Icon = getIcon(type);

  return (
    <div
      className={cx('Meta-option', `Meta-option_${type}`, className)}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {Icon}
      {children}
      <Transition in={isHovered} timeout={{ enter: 0, exit: 175 }} mountOnEnter unmountOnExit>
        {state => (
          <div className={cx('Meta-option__tooltip', `Meta-option__tooltip_${state}`)}>
            <FormattedMessage id={`characteristics.${type}.tooltip`} />
          </div>
        )}
      </Transition>
    </div>
  );
}

MetaOption.defaultProps = {
  className: '',
  children: null,
};

MetaOption.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
};
