import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import Heading from '../../Heading';
import Button from '../../Button';

// Icons
import RemoveIcon from '../../../icons/close.svg';
import ReturnIcon from '../../../icons/return.svg';
import FilterIcon from '../../../icons/card-filter.svg';

// State
import { getRemovedIngredientsCount } from '../../../state/modules/catalog/utils';

// Utils
import useOutsideClick from '../../../utils/useOutsideClick';

// Style
import styles from './Ingredients.styl';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

const cx = classNames.bind(styles);

export default function Ingredients(props) {
  const {
    className,
    variation,
    isAdding,
    stockRole,
    pushToConstructor,
    addToCart,
    remove,
    cancel,
    specialStylesMode,
  } = props;

  const intl = useIntl();
  const [isIngredientsShow, setIsIngredientsShow] = useState(false);
  const isHalloweenMode = useSelector(state => !!state.city.userCity.is_halloween_mode_on);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);

  // количество удаленных ингредиентов
  const removedIngredientsCount = useMemo(() => {
    return getRemovedIngredientsCount(variation) || 0;
  }, [variation]);

  const onIngredientsShow = useCallback(() => {
    setIsIngredientsShow(true);
  }, [setIsIngredientsShow]);

  const onIngredientsShowKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        onIngredientsShow();
      }
    },
    [onIngredientsShow],
  );

  const onAddToCart = useCallback(async () => {
    await addToCart();
    setIsIngredientsShow(false);
  }, [addToCart, setIsIngredientsShow]);

  const onClose = useCallback(() => {
    setIsIngredientsShow(false);
  }, [setIsIngredientsShow]);

  const onCancel = useCallback(() => {
    if (removedIngredientsCount > 0) {
      cancel();
    } else {
      onClose();
    }
  }, [removedIngredientsCount, cancel, onClose]);

  const onPushToConstructor = useCallback(() => {
    pushToConstructor();
  }, [pushToConstructor]);

  const nodeRef = useOutsideClick(onClose, isIngredientsShow);

  return (
    <div className={cx('Ingredients', className, {
      'Ingredients__halloween': isHalloweenMode && specialStylesMode,
      'Ingredients__new-year': isNewYearMode && specialStylesMode,
    })}>
      <div
        data-test-id="ingredients_button"
        className={cx('Ingredients__button')}
        onClick={onPushToConstructor}
        onKeyDown={onIngredientsShowKeyDown}
        tabIndex={0}
        role="button"
      >
        <FilterIcon className={cx('Ingredients__button-toggle')} />
        {Boolean(removedIngredientsCount) && (
          <div className={cx('Ingredients__button-counter')}>{removedIngredientsCount}</div>
        )}
      </div>
      {isIngredientsShow && (
        <>
          <div className={cx(`Ingredients__overlay`)} />
          <div className={cx(`Ingredients__popup`)} ref={nodeRef} data-test-id="ingredients_popup">
            <Heading className={cx('Ingredients__title')} level={3} tagName="div">
              {intl.formatMessage({ id: 'productCard.removeIngredients.title' })}
            </Heading>
            <div className={cx('Ingredients__body')}>
              <ul className={cx('Ingredients__list')}>
                {variation.include_ingredients?.map(ingredient => {
                    const isUndeleted = ingredient?.not_for_delete;
                    return (
                      <li
                        key={ingredient.id}
                        className={cx('Ingredients__list-item')}
                        onClick={() => isUndeleted ? noop() : remove(ingredient.id)}
                        // onKeyDown={e => e.key === 'Enter' && remove(ingredient.id)}
                        role="menuitem"
                        tabIndex={0}
                      >
                        <div
                          className={cx('Ingredients__item', {
                            Ingredients__item_removed: ingredient.removed,
                            Ingredients__item_disabled: isUndeleted,
                          })}
                        >
                          <div className={cx('Ingredients__item-name')}>{ingredient.name}</div>
                          {ingredient.removed ? (
                            <div className={cx('Ingredients__item-return')}>
                              <ReturnIcon width={16} height={16} />
                            </div>
                          ) : (
                            <div className={cx('Ingredients__item-remove')} data-test-id="ingredients_item_remove">
                              {!isUndeleted && <RemoveIcon width={12} height={12} />}
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  },
                )}
              </ul>
              {stockRole !== 'rewards' && (
                <Button className={cx('Ingredients__add')} onClick={onPushToConstructor} link>
                  <div className={cx('Ingredients__add-icon')} />
                  {intl.formatMessage({ id: 'button.addIngredients' })}
                </Button>
              )}
            </div>
            <div className={cx('Ingredients__footer')}>
              <Button className={cx('Ingredients__cancel')} link small onClick={onCancel}>
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
              <Button
                className={cx('Ingredients__cart-button')}
                onClick={onAddToCart}
                isLoading={isAdding}
              >
                {intl.formatMessage({ id: 'button.inCart' })}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

Ingredients.defaultProps = {
  className: '',
  isAdding: false,
  stockRole: undefined,
};

Ingredients.propTypes = {
  className: PropTypes.string,
  variation: PropTypes.object.isRequired,
  stockRole: PropTypes.string,
  pushToConstructor: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  isAdding: PropTypes.bool,
};
